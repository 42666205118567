import React from 'react'
import Header from '../../components/header'

const LoginWithNumber = (props) => {

    return (
        <>
            <div className="container-fluid d-flex flex-column align-items-center ml-2">
                <div>
                    <Header image="Globe" height={200} header="Mobile Validation" subHeader="Please enter your number to proceed." />
                    <div className="d-flex justify-content-start" style={{ gap: "10px" }}>
                        <input className="input-field" style={{ width: "66px" }} type="tel" value="+91" name="scan" placeholder="" />
                        <input className="input-field" maxLength={10} minLength={10} style={{ width: "190px" }} value={props?.customerNumber}
                            onChange={(event) => props?.handleChangeMobile(event)} type="tel" name="scan" placeholder="" />
                    </div>
                    {props?.customerNumber && props?.customerNumber?.length < 10 ? <p className="d-flex justify-content-start mt-2 error-input">Invalid Number</p> : ""}
                    <div className=" mt-4" >
                        <button style={{ width: "270px" }} onClick={() => props?.getMobileNumberToOtp()} >Send OTP</button>
                    </div>
                    <p className='mt-5' style={{ width: "330px", fontSize: "13px" }}>
                        Country Code Selection would be enabled for codes having an active International roaming plans.
                        If you need to activate an International roaming plan, <br />
                        <b>Click Here</b>
                    </p>
                </div>
            </div>
        </>
    )
}

export default LoginWithNumber