import React from "react";
const Header = ({ image, height = 450, content = "start", header, subHeader, subColor = "black" }) => {
    return (
        <>
            <div className={`d-flex justify-content-${content}`} >
                <img height={height} src={`/images/${image}.png`} alt="" />
            </div>
            <div className="mt-4" >
                <p className="sorry" >{header}<br /></p>
                <p style={{ marginTop: "-20px", fontSize: "16px", width: "245px", fontWeight: subColor ? "600":"400", color: subColor }}><br />{subHeader}</p><br />
            </div>
        </>
    )
}


export default Header;